.details-image {
  box-sizing: border-box;
  width: 100%;
  border-radius: 22px;

  @media (min-width: 1024px) {
    width: 44%;
  }
}

.locationContainer {
  width: 100%;
  border-radius: 16px;
}
