.bread-crumbs-container {
  display: flex;
  align-items: center;
  height: 20px;
}

.bread-crumbs-text-container {
  display: flex;
  align-items: center;
  height: 20px;
  gap: 3px;
  text-decoration: none;
  font-size: 15px;
}

.text {
  height: 32px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  color: #54198b;
}
