.notification {
  position: fixed;
  box-sizing: border-box;
  z-index: 100;
  top: 0px;
  right: 0px;
  margin: 24px 24px 0px 24px;
  display: inline-block;
  width: -webkit-fill-available;
  padding: 15px;
  border-radius: 4px;
  background-color: #141619;
  color: #f6f5f9;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  animation: fade 5s linear forwards;
}

@media (min-width: 600px) {
  .notification {
    width: 350px;
  }
}

.progress {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 0;
  height: 3px;
  background-image: linear-gradient(to right, #529bdb, var(--main));
  border-radius: 4px;
  animation: progress 4s 0.35s linear forwards;
}

@keyframes fade {
  5% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }

  90% {
    opacity: 1;
    transform: translateY(0px);
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

@keyframes progress {
  to {
    width: calc(100% - 10px);
  }
}
