.send-button {
  width: 142.5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  font-size: 16px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 48px;
  border-radius: 100px;
  border: solid 2px var(--main);
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: var(--main);
  padding: 0px 45px 0;
  font-weight: normal;
}

.send-button:hover {
  border: solid 2px var(--main);
  background-color: var(--main);
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #ffffff;
}
.error {
  border: solid 2px red;
  background-color: red;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #ffffff;
}
.error:hover {
  border: solid 2px red;
  background-color: red;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #ffffff;
}
