.start-img {
  width: 400px;

  @media (max-width: 1200px) {
    width: 350px;
  }

  @media (max-width: 1000px) {
    width: 300px;
  }
}
.choise-img {
  width: 360px;

  @media (max-width: 1200px) {
    width: 310px;
  }

  @media (max-width: 1000px) {
    width: 200px;
  }

  @media (max-width: 500px) {
    width: 250px;
  }
}