.hts-icon {
  position: absolute;
  // animation: animate 2s infinite ease alternate;

  @keyframes animate {
    to {
      transform: translateY(10px);
    }
  }
}

.hts-blob-icon {
  height: 400px;
  width: 400px;
  position: absolute;
  background-color: #a68cf2;
  animation: morph 5s linear infinite;

  @keyframes morph {
    0%,
    100% {
      border-radius: 20% 60% 50% 30% / 40% 40% 60% 50%;
    }
    34% {
      border-radius: 70% 30% 70% 50% / 30% 30% 70% 70%;
    }
    67% {
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    }
  }
}
