.link {
  text-decoration: none;
  color: #999999;
}

.social-media-icon {
  color: var(--black);
}

.social-media-icon-wrapper {
  cursor: pointer;
  background-color: #ffff;
  width: 35px;
  height: 35px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.instagram {
  margin: 3px 0px 0px 1px;
}

.social-media-icon-wrapper:hover {
  background-color: var(--main);
  transition: transform 0.3s ease-in-out;
  transform: scale(1.1, 1.1);

  svg {
    fill: #ffff;
  }
}
