.language-icon {
  width: 60px;
}

.language-wrapper {
  width: 100%;
  padding: 14px 25px;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  display: flex;
  cursor: pointer;
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.15);
}

.language-info-block {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  height: 100%;
}

.check-icon {
  width: 28px;
}
