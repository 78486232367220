body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: 'Montserrat';
}

:root {
  --main: #a68cf1;
  --main-light: #f7f4ff;
  --light-gray: #8c949e;
  --white: #ffff;
  --black: #1c252b;
  --header-height: 76px;
  --mobile-header-height: 64px;
  --gold: #feca7c;
  --swiper-navigation-size: 28px !important;
  --swiper-navigation-color: #a68cf1 !important;
}

.css-1wuw8dw-MuiBreadcrumbs-separator {
  margin: 0px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
