.loader {
  place-content: center;
  width: 70px;
  height: 70px;
  background: radial-gradient(farthest-side, #676aff 94%, #0000) top/9px 9px no-repeat,
    conic-gradient(#f7f4ff, 30%, #676aff);
  animation: spin 0.5s linear infinite;
}

.loader::after {
  content: '';
  width: 54px;
  height: 54px;
  background: #f7f4ff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader,
.loader::after {
  border-radius: 50%;
  display: grid;
}

.loader-wrapper {
  z-index: 10000;
  position: absolute;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f4ff;
}
