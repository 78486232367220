.oops-image {
  width: 220px;
  height: 220px;
}

@media (min-width: 700px) {
  .oops-image {
    width: 350px;
    height: 350px;
  }
}
