.feedback-card-text {
  height: 70px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.feedback-card-text-open {
  height: 100%;
  text-align: left;
}

.avatar-wrapper {
  border: 3px solid var(--main);
  border-radius: 50%;
}

.avatar-wrapper img {
  border-radius: 50%;
  width: 55px;
  height: 55px;
}

.author-name {
  width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;

  position: relative;
}

.author-name::after {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  width: 15px;
  background-image: linear-gradient(to right, transparent, white);
  pointer-events: none;
}
