.gradient-btn {
  background-color: transparent;
  position: relative;
  overflow: hidden;
  padding: 12px 24px;
  font-size: 16px;
  z-index: 2;
  color: white;
  border-radius: 12px;
  border: none;
  line-height: 1.65;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  outline: none;
  cursor: pointer;

  @media (min-width: 0px) and (max-width: 450px) {
    width: 100%;
  }
  @media (min-width: 450px) {
    width: 320px;
  }
}

.gradient-btn::before {
  content: '';
  background: linear-gradient(to right, #77bdff, var(--main), #ff7777);
  position: absolute;
  inset: 0;
  width: 200%;
  z-index: -1;
  transition: left 500ms;
  animation: slide 7s linear infinite;
}

@keyframes slide {
  0% {
    left: 0;
  }
  50% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}
