.mobile-link {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  background-color: inherit;
  cursor: pointer;
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
  gap: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 500 !important;
}

.burger-menu {
  display: flex;
  align-items: center;
  gap: 20px;
}

.wrapper {
  position: sticky;
  top: 0;
  background-color: var(--main);
  border-radius: 0px 0px 20px 20px;
  z-index: 100;
  margin-bottom: -20px;
  width: 100%;
}

nav {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  justify-content: space-between;
}

.menu-wrapper {
  padding: 20px 32px;
  width: 100%;
  height: 100vh;
  background-color: var(--main);
  z-index: 100;
  transition: all 0.5s;
}

.menu-content {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.menu {
  position: fixed;
  top: 0;
  width: 60vw;
  height: 100vh;
  transform: translateX(-300%);
  transition: all 0.5s;
  left: 0;
  z-index: 101;
}

.menu.active {
  transition: all 0.5s;
  transform: translateX(0);
}

.blur {
  width: 100vw;
  height: 100vh;
  left: 100%;
  position: absolute;
}

.nav-icon {
  width: 30px;
  height: 22px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: var(--white);
  border-radius: 9px;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  cursor: pointer;
}

.nav-icon span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon span:nth-child(2) {
  top: 10px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon span:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 6.7px;
}

.nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.nav-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 18px;
  left: 6px;
}
