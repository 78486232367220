.title-container {
  height: 140px;
  color: var(--white);
  font-size: 38px;
  font-weight: 600;
}

@media (min-width: 490px) {
  .title-container {
    height: 93px;
  }
}

@media (min-width: 746px) {
  .title-container {
    height: 47px;
  }
}

@media (min-width: 767px) {
  .title-container {
    height: 140px;
  }
}

@media (min-width: 934px) {
  .title-container {
    height: 93px;
  }
}
@media (min-width: 1360px) {
  .title-container {
    height: 47px;
  }
}

.back-image {
  width: 700px;
  height: 570px;
}

@media (max-width: 600px) {
  .back-image {
    width: 500px;
    height: 400px;
    margin-bottom: 130px;
  }
}

@media (min-width: 601px) {
  .back-image {
    width: 650px;
    height: 500px;
  }
}

@media (min-width: 1000px) {
  .back-image {
    width: 700px;
    height: 550px;
  }
}
