.photo {
  width: 634px;
  height: 611px;
  object-fit: cover;

  @media (min-width: 0px) and (max-width: 900px) {
    width: 300px;
  }
}

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;

.google-btn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 52.5px;
  cursor: pointer;
  background-color: $google-blue;
  border-radius: 12px;

  .google-icon-wrapper {
    margin: 1.5px 0px 1.5px 1.5px;
    width: 20%;
    height: 49.5px;
    border-radius: 10px 0px 0px 10px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .google-icon {
    width: 22px;
    height: 22px;
  }

  .btn-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
}

.back-btn {
  border: 1px solid #e7e7e7;
  background: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
}

.google-sign-in {
  width: 100%;
  height: 48.5px;
  background-color: $google-blue;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
}

.google-button {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  margin-left: 40px;
}

.link-style {
  text-decoration: none;
  font-weight: 600;
  color: var(--main);
}
