.card-text {
  height: 70 px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.choose-card:hover {
  cursor: pointer;
  transform: scale(1.04, 1.04);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 0px 50px 25px rgb(166, 140, 241, 0.09);
}
.choose-card {
  transition: transform 0.3s ease-in-out;
}
