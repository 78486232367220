.upload-input {
  opacity: 0;
  position: absolute;
  width: 82px;
  height: 82px;
  border-radius: 50px;
  cursor: pointer;
}

.upload-wrapper {
  width: 82px;
  height: 82px;
  border-radius: 50px;
  background: var(--main);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
