.link a {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: white;
}

.link a::after {
  content: '';
  position: absolute;
  height: 3px;
  background: #fff;
  width: 100%;
  left: 0;
  bottom: -6px;
  border-radius: 6px;
  transform-origin: right;
  transform: scaleX(0);
  transition: 0.2s;
}

.link a:hover::after {
  transform: scaleX(1);
}

.active a::after {
  content: '';
  position: absolute;
  height: 3px;
  background: #fff;
  width: 100%;
  left: 0;
  border-radius: 6px;
  transition: 0.2s;
  transform: scaleX(1);
}

.mobile-link a {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  background-color: inherit;
  cursor: pointer;
  color: var(--white);
  text-decoration: none;
  font-size: 18px;
  gap: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
}

.mobile-active a {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 12px 10px 12px;
  border-radius: 6px;
  color: var(--main);
  cursor: pointer;
  background-color: #f7f4ff;
  text-decoration: none;
  font-size: 18px;
  gap: 10px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
}

.mobile-active a::after {
  content: '';
  display: none;
}

.mobile-link a::after {
  content: '';
  display: none;
}
