.language-test-img {
  width: 600px;
  height: 600px;

  @media (max-width: 1200px) {
    width: 450px;
    height: 450px;
  }
  @media (max-width: 900px) {
    width: 350px;
    height: 350px;
  }
}

.mobile-language-test-img {
  width: 100%;
}

.css-26emvo-MuiTypography-root {
  font-weight: 500 !important;
  font-size: 18px !important;
}
