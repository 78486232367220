.swiper-feedbacks {
  width: 100%;
}

.swiper-feedbacks-slide {
  font-size: 18px;
  cursor: pointer;
}

.swiper-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
}
