.user-avatar {
  border: 3px solid var(--white);
  overflow: hidden;
  border-radius: 50%;
  box-sizing: border-box;
  object-fit: cover;
}

.comment {
  width: 55px;
  height: 55px;
}

.small {
  width: 46px;
  height: 46px;
  border: 3px solid var(--main);
}

.medium {
  width: 102px;
  height: 102px;
  border: 4px solid var(--white);
}

.large {
  width: 140px;
  height: 140px;
}

.avatar-border {
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.large-border {
  border: 2px solid var(--white);
}

.small-border {
  border: 3px solid var(--white);
}

.medium-border {
  border: 3px solid var(--main);
}

.comment-border {
  border: 3px solid var(--main);
}
