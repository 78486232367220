.background-wrapper {
  background-image: url('../../assets/TestStart/blob_background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.robot {
  position: absolute;
  left: 75%;
  margin-top: -180px;
  z-index: 2;

  @media (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 0px;
    opacity: 0.2;
  }

  @media (min-width: 900px) {
    left: 55%;
    margin-top: -440px;
  }

  @media (min-width: 1000px) {
    left: 60%;
    margin-top: -360px;
  }

  @media (min-width: 1300px) {
    left: 70%;
    margin-top: -350px;
  }
}
