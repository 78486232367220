.chart-container {
  height: 10px;
  width: 100%;
  background-color: rgb(236, 236, 236);
  border-radius: 5px;
  transition: width 1s ease-in-out;
}

.filled-line {
  height: 100%;
  border-radius: inherit;
  transition: width 1s ease-in-out;
}
